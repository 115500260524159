import { render, staticRenderFns } from "./comAtNu.vue?vue&type=template&id=aa85a166&scoped=true&"
import script from "./comAtNu.vue?vue&type=script&lang=js&"
export * from "./comAtNu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa85a166",
  null
  
)

export default component.exports